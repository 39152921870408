import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { PublicationComponent as BaseComponent } from '../../../../../../../app/item-page/simple/item-types/publication/publication.component';
import * as  CiteProc from 'citeproc';
import { HttpClient } from '@angular/common/http';
import {RouteService} from "../../../../../../../app/core/services/route.service";
import {Router} from "@angular/router";

import {environment} from "../../../../../../../environments/environment";

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Publication', ViewMode.StandalonePage, Context.Any, 'sub')
@Component({
  selector: 'ds-publication',
  styleUrls: ['./publication.component.scss'],
  // styleUrls: ['../../../../../../../app/item-page/simple/item-types/publication/publication.component.scss'],
  templateUrl: './publication.component.html',
  // templateUrl: '../../../../../../../app/item-page/simple/item-types/publication/publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationComponent extends BaseComponent {

  /**
   * SUB Hamburg / eWW
   * CC badge object to display info on publication page
   */
  public ccBadge = {
    'url' : '',
    'img' : '',
  };

  private citationStyles: { [key: string]: string } = {
    "DIN_1505-2": "din-1505-2",
    "APA": "apa",
  };

  public citations = [];

  private apiUrl = `${environment.rest.baseUrl}/api/metadata-export/`; // Adjust if needed
  selectedFormat: string = 'bibtex';

  public usageStats: any;
  public reportData: any[] = [];

  constructor(protected routeService: RouteService,
              protected router: Router,
              private http: HttpClient) {
    super(routeService, router);
  }

  /**
   * SUB Hamburg / eWW
   * Added call to ngOnInit() to initialize CC badges
   */
  ngOnInit() {
    super.ngOnInit();
    this.initCcBadges();
    this.initCitations();
    this.initStatistics();
  }

  /**
   * SUB Hamburg / eWW
   * Initialize CC badges and links
   */
  initCcBadges () {
    const badges = {
      'https://creativecommons.org/publicdomain/zero/1.0/' : ['cc-zero.svg'],
      'https://creativecommons.org/licenses/by/4.0/' : ['by.svg'],
      'https://creativecommons.org/licenses/by-sa/4.0/' : ['by-sa.svg'],
      'https://creativecommons.org/licenses/by-nd/4.0/' : ['by-nd.svg'],
      'https://creativecommons.org/licenses/by-nc/4.0/' : ['by-nc.eu.svg'],
      'https://creativecommons.org/licenses/by-nc-sa/4.0/' : ['by-nc-sa.eu.svg'],
      'https://creativecommons.org/licenses/by-nc-nd/4.0/' : ['by-nc-nd.eu.svg'],
    };
    for (const metadataField of Object.keys(this.object.metadata)) {
      if (metadataField == 'dc.rights') {
        this.ccBadge = {
          'url' : this.object.metadata[metadataField][0]['value'],
          'img' : badges[this.object.metadata[metadataField][0]['value']],
        };
      }
    }
  }

  /**
   * SUB Hamburg / eWW
   * Initialize citations
   */
  initCitations () {

    const citationItems = {
      "item": {
        id: "item",
        /* type: "book",
        author: [
          { family: "Doe", given: "John" },
          { family: "Smith", given: "Jane" },
          { family: "Brown", given: "James" },
          { family: "White", given: "Sally" }
        ],
        title: "Sample Book",
        issued: { "date-parts": [[2020]] }, */
      },
    };

    const dcType = this.getMetadataForKey('dc.type')
    if (dcType.length > 0) {
      for (const dcTypeItem of dcType) {
        citationItems['item']['type'] = dcTypeItem.toLowerCase();
      }
    }

    const dcTitle = this.getMetadataForKey('dc.title')
    if (dcTitle.length > 0) {
      for (const dcTitleItem of dcTitle) {
        citationItems['item']['title'] = dcTitleItem;
        const dcTitleSubtitle = this.getMetadataForKey('dc.title.subtitle')
        for (const dcTitleSubtitleItem of dcTitleSubtitle) {
          if (dcTitleSubtitle.length > 0) {
            citationItems['item']['title'] += ' : ' + dcTitleSubtitleItem;
          }
        }
      }
    }

    const dcContributorAuthor = this.getMetadataForKey('dc.contributor.author')
    if (dcContributorAuthor.length > 0) {
      for (const dcContributorAuthorItem of dcContributorAuthor) {
        var valueArrAuthor = dcContributorAuthorItem.split(',');
        if (!citationItems['item']['author']) {
          citationItems['item']['author'] = [];
        }
        citationItems['item']['author'].push({'family': valueArrAuthor[0].trim().toUpperCase(), 'given': valueArrAuthor[1].trim()});
      }
    }

    const dcContributorEditor = this.getMetadataForKey('dc.contributor.editor')
    if (dcContributorEditor.length > 0) {
      for (const dcContributorEditorItem of dcContributorEditor) {
        var valueArrEditor = dcContributorEditorItem.split(',');
        if (!citationItems['item']['editor']) {
          citationItems['item']['editor'] = [];
        }
        citationItems['item']['editor'].push({'family': valueArrEditor[0].trim().toUpperCase(), 'given': valueArrEditor[1].trim()});
      }
    }

    const dcContributorOther = this.getMetadataForKey('dc.contributor.other')
    if (dcContributorOther.length > 0) {
      for (const dcContributorOtherItem of dcContributorOther) {
        var valueArrOther = dcContributorOtherItem.split(',');
        if (!citationItems['item']['other']) {
          citationItems['item']['other'] = [];
        }
        citationItems['item']['other'].push({'family': valueArrOther[0].trim().toUpperCase(), 'given': valueArrOther[1].trim()});
      }
    }

    const dcDateIssued = this.getMetadataForKey('dc.date.issued')
    if (dcDateIssued.length > 0) {
      for (const dcDateIssuedItem of dcDateIssued) {
        var valueArrDateIssued = dcDateIssuedItem.split('-');
        citationItems['item']['issued'] = {};
        citationItems['item']['issued']['date-parts'] = [valueArrDateIssued];
      }
    }

    const dcIdentifierDoi = this.getMetadataForKey('dc.identifier.doi')
    if (dcIdentifierDoi.length > 0) {
      for (const dcIdentifierDoiItem of dcIdentifierDoi) {
        citationItems['item']['DOI'] = dcIdentifierDoiItem;
      }
    }

    const dcIdentifierUrl = this.getMetadataForKey('dc.identifier.url')
    if (dcIdentifierUrl.length > 0) {
      for (const dcIdentifierUrlItem of dcIdentifierUrl) {
        citationItems['item']['URL'] = dcIdentifierUrlItem;
      }
    }

    const dcIdentifierUri = this.getMetadataForKey('dc.identifier.uri')
    if (dcIdentifierUri.length > 0) {
      for (const dcIdentifierUriItem of dcIdentifierUri) {
        citationItems['item']['URL'] = dcIdentifierUriItem;
      }
    }

    const getCurrentDate = (): (number | string)[] => {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return [year, month, day];
    };
    citationItems['item']['accessed'] = {};
    citationItems['item']['accessed']['date-parts'] = [getCurrentDate()];

    console.log(citationItems);

    for (var citationStyleKey in this.citationStyles) {
      const citeprocEngine = this.getProcessor(this.citationStyles[citationStyleKey], citationItems);
      citeprocEngine.updateItems(["item"]);
      const bibliography = citeprocEngine.makeBibliography();
      this.citations.push({ title: citationStyleKey, details: bibliography[1][0], showDetails: false });
    }
  }

  toggleDetails(index: number) {
    this.citations[index].showDetails = !this.citations[index].showDetails;
  }

  getProcessor(styleID, citationItems) {
    const citeprocSys = {
      retrieveLocale: function (lang){
        var xhr = new XMLHttpRequest();
        xhr.open('GET', '/assets/sub/locales/locales-' + lang + '.xml', false);
        xhr.send(null);
        return xhr.responseText;
      },
      retrieveItem: function(id){
        return citationItems[id];
      }
    };

    var xhr = new XMLHttpRequest();
    xhr.open('GET', '/assets/sub/styles/' + styleID + '.csl', false);
    xhr.send(null);
    var styleAsText = xhr.responseText;
    return new CiteProc.Engine(citeprocSys, styleAsText);
  };

  getMetadataForKey (metadataKey) {
    const result = [];
    for (const metadataFieldKey of Object.keys(this.object.metadata)) {
      if (metadataFieldKey == metadataKey) {
        result.push(this.object.metadata[metadataFieldKey][0]['value']);
      }
    }
    return result;
  }

  downloadFile(format: string): void {
    this.http.get(this.apiUrl+this.object.uuid+'/'+format, { responseType: 'blob' }).subscribe({
      next: (blob) => {
        let fileName: string;
        switch (format) {
          case 'bibtex':
            fileName = 'export.bib';
            break;
          case 'endnote':
            fileName = 'export.enw';
            break;
          case 'csv':
            fileName = 'export.csv';
            break;
          default:
            fileName = 'export.txt';
            break;
        }

        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);
      },
      error: (error) => {
        console.error('Download error:', error);
      }
    });
  }

  initStatistics () {
    const statisticUrl= `${environment.rest.baseUrl}/api/statistics/usagereports/search/object?uri=${environment.rest.baseUrl}/api/core/item/${this.object.uuid}`;
    this.http.get(statisticUrl).subscribe((stats) => {
      this.usageStats = stats;
      if (this.usageStats._embedded && this.usageStats._embedded.usagereports) {
        this.reportData = this.usageStats._embedded.usagereports;
      }
    });
  }
}
