<div *ngIf="!(processingConnection | async) && (item | async)" class="container">
  <div class="button-row bottom mb-3">
    <div class="text-right">
      <a [routerLink]="getItemPage()" role="button" class="btn btn-outline-secondary" data-test="back-button">
        <i class="fas fa-arrow-left"></i> {{'item.orcid.return' | translate}}
      </a>
    </div>
  </div>
</div>

<ds-loading *ngIf="(processingConnection | async)" [message]="'person.page.orcid.link.processing' | translate"></ds-loading>
<div class="container" *ngIf="!(processingConnection | async) && !(connectionStatus | async)" data-test="error-box">
  <ds-alert [type]="'alert-danger'">{{'person.page.orcid.link.error.message' | translate}}</ds-alert>
</div>
<ng-container *ngIf="!(processingConnection | async) && (item | async) && (connectionStatus | async)" >
  <ds-orcid-auth [item]="(item | async)" (unlink)="updateItem()" data-test="orcid-auth"></ds-orcid-auth>
  <ds-orcid-sync-setting *ngIf="isLinkedToOrcid()" [item]="(item | async)" (settingsUpdated)="updateItem()" data-test="orcid-sync-setting"></ds-orcid-sync-setting>
  <ds-orcid-queue *ngIf="isLinkedToOrcid()" [item]="(item | async)"></ds-orcid-queue>
</ng-container>
