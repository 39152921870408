<ds-themed-results-back-button *ngIf="showBackButton | async" [back]="back"></ds-themed-results-back-button>
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer  id="iiif-viewer"
                        [object]="object"
                        [searchable]="iiifSearchEnabled"
                        [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <ds-dso-edit-menu></ds-dso-edit-menu>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!(mediaViewer.image || mediaViewer.video)">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <div *ngIf="mediaViewer.image || mediaViewer.video" class="mb-2">
      <ds-themed-media-viewer [item]="object"></ds-themed-media-viewer>
    </div>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.creator']"
                                            [label]="'relationships.isAuthorOf' | translate">
    </ds-themed-metadata-representation-list>

    <!-- SUB Hamburg / eWW -->
    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.contributor.other']"
                                            [label]="'relationships.isCoAuthorOf' | translate">
    </ds-themed-metadata-representation-list>

    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.contributor.editor']"
                                            [label]="'relationships.isEditorOf' | translate">
    </ds-themed-metadata-representation-list>

    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.contributor']"
                                            [label]="'relationships.isContributorOf' | translate">
    </ds-themed-metadata-representation-list>

    <ds-themed-metadata-representation-list class="ds-item-page-mixed-author-field"
                                            [parentItem]="object"
                                            [itemType]="'Person'"
                                            [metadataFields]="['dc.contributor.advisor']"
                                            [label]="'relationships.isAdvisorOf' | translate">
    </ds-themed-metadata-representation-list>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.identifier.uri']"
                                [label]="'publication.page.uri'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.identifier.doi']"
                                [label]="'publication.page.doi'">
    </ds-generic-item-page-field>
    <div class="cc-badge">
      <a href="{{ ccBadge.url }}" target="_blank">
        <img src="assets/sub/images/cc-badges-svg/{{ ccBadge.img }}" alt="{{ ccBadge.url }}" />
      </a>
    </div>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
    <!-- SUB Hamburg / eWW -->
  </div>
  <div class="col-xs-12 col-md-7">
    <!-- SUB Hamburg / eWW -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.type']"
                                [label]="'publication.page.type'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dcterms.bibliographicCitation.booktitle', 'dc.source']"
                                [label]="'publication.page.source'">
    </ds-generic-item-page-field>
    <!-- SUB Hamburg / eWW -->
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <!-- SUB Hamburg / eWW -->
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.subject.ddc']"
                                [label]="'publication.page.ddc'">
    </ds-generic-item-page-field>
    <!-- SUB Hamburg / eWW -->
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfPublication'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
