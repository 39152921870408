<footer class="sub-footer">
	<section class="container">

	<article>
		<ul class="footer-menu lead">
			<li><a href="info/feedback">{{ 'footer.link.contact' | translate}}</a></li>
			<li><a href="/login">{{ 'footer.link.login' | translate}}</a></li>
			<li><a routerLink="/static-pages/guidelines">{{ 'footer.link.guidelines' | translate}}</a></li>
      		<li><a routerLink="/static-pages/faq">{{ 'footer.link.faq' | translate}}</a></li>
      	</ul>
      	<ul class="footer-menu">
      		<li><a routerLink="/static-pages/terms-of-use">{{ 'footer.link.terms-of-use' | translate}}</a></li>
			<li><a href="https://www.sub.uni-hamburg.de/impressum.html" target="_blank">{{ 'footer.link.imprint' | translate}}</a></li>
			<li><a routerLink="/static-pages/privacy">{{ 'footer.link.privacy' | translate}}</a></li>
			<li><a href="javascript:void(0);" (click)="showCookieSettings()">{{ 'footer.link.cookies' | translate}}</a></li>
		</ul>
	</article>

	<article>
		<div class="footer-logo">
			<span class="footer-logo-prefix">{{ 'footer.logo.prefix' | translate}}</span>
			<a href="https://www.sub.uni-hamburg.de" target="_blank"><img src="assets/sub/images/logo-subhh.svg" [attr.alt]="'menu.header.image.logo' | translate"/></a>
			<a href="https://www.hamburg.de" target="_blank"><img src="assets/sub/images/logo-hh.svg" [attr.alt]=" "/></a>
		</div>
	</article>

  </section>
</footer>
