import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { PublicationComponent as BaseComponent } from '../../../../../../../app/item-page/simple/item-types/publication/publication.component';

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Publication', ViewMode.StandalonePage, Context.Any, 'sub')
@Component({
  selector: 'ds-publication',
  // styleUrls: ['./publication.component.scss'],
  styleUrls: ['../../../../../../../app/item-page/simple/item-types/publication/publication.component.scss'],
  templateUrl: './publication.component.html',
  // templateUrl: '../../../../../../../app/item-page/simple/item-types/publication/publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationComponent extends BaseComponent {

  /**
   * SUB Hamburg / eWW
   * CC badge object to display info on publication page
   */
  public ccBadge = {
    'url' : '',
    'img' : '',
  };

  /**
   * SUB Hamburg / eWW
   * Added call to ngOnInit() to initialize CC badges
   */
  ngOnInit() {
    super.ngOnInit();
    this.initCcBadges();
  }

  /**
   * SUB Hamburg / eWW
   * Initialize CC badges and links
   */
  initCcBadges () {
    const badges = {
      'https://creativecommons.org/publicdomain/zero/1.0/' : ['cc-zero.svg'],
      'https://creativecommons.org/licenses/by/4.0/' : ['by.svg'],
      'https://creativecommons.org/licenses/by-sa/4.0/' : ['by-sa.svg'],
      'https://creativecommons.org/licenses/by-nd/4.0/' : ['by-nd.svg'],
      'https://creativecommons.org/licenses/by-nc/4.0/' : ['by-nc.eu.svg'],
      'https://creativecommons.org/licenses/by-nc-sa/4.0/' : ['by-nc-sa.eu.svg'],
      'https://creativecommons.org/licenses/by-nc-nd/4.0/' : ['by-nc-nd.eu.svg'],
    };
    for (const metadataField of Object.keys(this.object.metadata)) {
      if (metadataField == 'dc.rights') {
        this.ccBadge = {
          'url' : this.object.metadata[metadataField][0]['value'],
          'img' : badges[this.object.metadata[metadataField][0]['value']],
        };
      }
    }
  }
}
