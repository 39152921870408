<ng-template #descTemplate>
  <span class="text-muted">
      <span class="item-list-job-title">
        <span [innerHTML]="mdRepresentation.firstMetadataValue(['dc.description'])"></span>
      </span>
  </span>
</ng-template>
<ds-truncatable [id]="mdRepresentation.id">
  <a [routerLink]="[itemPageRoute]"
     [innerHTML]="mdRepresentation.getValue()"
     [ngbTooltip]="mdRepresentation.allMetadata(['dc.description']).length > 0 ? descTemplate : null"></a>
</ds-truncatable>
