<div #sdRef="ngbDropdown" ngbDropdown display="dynamic" placement="bottom-right" class="w-100">
  <div class="position-relative right-addon"
       role="combobox"
       [attr.aria-label]="model.label"
       [attr.aria-owns]="'combobox_' + id + '_listbox'">
       <i *ngIf="!model.readOnly" ngbDropdownToggle class="position-absolute scrollable-dropdown-toggle"
          aria-hidden="true"></i>
       <i *ngIf="model.readOnly" class="dropdown-toggle position-absolute toggle-icon"
          aria-hidden="true"></i>
    <input class="form-control"
           [attr.aria-controls]="'combobox_' + id + '_listbox'"
           [attr.aria-activedescendant]="'combobox_' + id + '_selected'"
           [attr.aria-label]="model.placeholder"
           [attr.autoComplete]="model.autoComplete"
           [class.is-invalid]="showErrorMessages"
           [class.scrollable-dropdown-input]="!model.readOnly"
           [id]="id"
           [name]="model.name"
           [readonly]="true"
           [disabled]="model.readOnly"
           [type]="model.inputType"
           [value]="(currentValue | async)"
           (blur)="onBlur($event)"
           (click)="$event.stopPropagation(); openDropdown(sdRef);"
           (focus)="onFocus($event)"
           (keydown)="selectOnKeyDown($event, sdRef)">
  </div>

  <div ngbDropdownMenu
       class="dropdown-menu scrollable-dropdown-menu w-100"
       aria-haspopup="true"
       aria-expanded="false"
       [attr.aria-label]="model.placeholder">
    <div class="scrollable-menu"
         role="listbox"
         [id]="'combobox_' + id + '_listbox'"
         [attr.aria-label]="model.placeholder"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScroll()"
         [scrollWindow]="false">

      <button class="dropdown-item disabled" *ngIf="optionsList && optionsList.length == 0">{{'form.no-results' | translate}}</button>
      <button class="dropdown-item collection-item text-truncate" *ngFor="let listEntry of optionsList"
              (keydown.enter)="onSelect(listEntry); sdRef.close()" (mousedown)="onSelect(listEntry); sdRef.close()"
              title="{{ listEntry.display }}" role="option"
              [attr.id]="listEntry.display == (currentValue|async) ? ('combobox_' + id + '_selected') : null">
        {{inputFormatter(listEntry)}}
      </button>
      <div class="scrollable-dropdown-loading text-center" *ngIf="loading"><p>{{'form.loading' | translate}}</p></div>
    </div>

  </div>
</div>
