<ng-template #descTemplate>
  <span class="text-muted">
      <span *ngIf="mdRepresentation.allMetadata(['person.jobTitle']).length > 0"
            class="item-list-job-title">
              <span *ngFor="let value of mdRepresentation.allMetadataValues(['person.jobTitle']); let last=last;">
                  <span [innerHTML]="value"><span [innerHTML]="value"></span></span>
              </span>
      </span>
  </span>
</ng-template>
<ds-truncatable [id]="mdRepresentation.id">
  <a [routerLink]="[itemPageRoute]"
     [innerHTML]="mdRepresentation.getValue()"
     [ngbTooltip]="mdRepresentation.allMetadata(['person.jobTitle']).length > 0 ? descTemplate : null"></a>
  <a *ngFor="let value of mdRepresentation.allMetadataValues(['person.identifier.orcid']); let last=last;"
     [ngbTooltip]="'ORCID'"
     class="orcid-link"
     href="https://orcid.org/{{value}}"
     target="_blank">
    <span class="logo"><img src="assets/sub/images/orcid_16x16.png" [attr.alt]="'orcid.logo' | translate"/></span>
  </a>
  <a *ngFor="let value of mdRepresentation.allMetadataValues(['person.identifier.gnd']); let last=last;"
     [ngbTooltip]="'GND'"
     class="gnd-link"
     href="https://explore.gnd.network/gnd/{{value}}"
     target="_blank">
    <span class="logo"><img src="assets/sub/images/gnd_16x16.png" [attr.alt]="'gnd.logo' | translate"/></span>
  </a>
</ds-truncatable>
