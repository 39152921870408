<div class="container mb-5">
  <h2>{{'person.orcid.registry.auth' | translate}}</h2>
  <ng-container *ngIf="(isLinkedToOrcid() | async); then orcidLinked; else orcidNotLinked"></ng-container>
</div>

<ng-template #orcidLinked>
  <div data-test="orcidLinked">
    <div class="row">
      <div *ngIf="(hasOrcidAuthorizations() | async)" class="col-sm-6 mb-3" data-test="hasOrcidAuthorizations">
        <div class="card h-100">
          <div class="card-header">{{ 'person.page.orcid.granted-authorizations'| translate }}</div>
          <div class="card-body">
            <div class="container p-0">
              <ul>
                <li *ngFor="let auth of (getOrcidAuthorizations() | async)" data-test="orcidAuthorization">
                  {{getAuthorizationDescription(auth) | translate}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-3">
        <div class="card h-100">
          <div class="card-header">{{ 'person.page.orcid.missing-authorizations'| translate }}</div>
          <div class="card-body">
            <div class="container">
              <ds-alert *ngIf="!(hasMissingOrcidAuthorizations() | async)" [type]="'alert-success'" data-test="noMissingOrcidAuthorizations">
                {{'person.page.orcid.no-missing-authorizations-message' | translate}}
              </ds-alert>
              <ds-alert *ngIf="(hasMissingOrcidAuthorizations() | async)" [type]="'alert-warning'" data-test="missingOrcidAuthorizations">
                {{'person.page.orcid.missing-authorizations-message' | translate}}
                <ul>
                  <li *ngFor="let auth of (getMissingOrcidAuthorizations() | async)" data-test="missingOrcidAuthorization">
                    {{getAuthorizationDescription(auth) | translate }}
                  </li>
                </ul>
              </ds-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ds-alert *ngIf="(onlyAdminCanDisconnectProfileFromOrcid() | async) && !(ownerCanDisconnectProfileFromOrcid() | async)"
              [type]="'alert-warning'" data-test="unlinkOnlyAdmin">
      {{ 'person.page.orcid.remove-orcid-message' | translate}}
    </ds-alert>
    <div class="row" *ngIf="(ownerCanDisconnectProfileFromOrcid() | async)" data-test="unlinkOwner">
      <div class="col">
        <button type="submit" class="btn btn-danger float-right" (click)="unlinkOrcid()"
                [disabled]="(unlinkProcessing | async)">
          <span *ngIf="!(unlinkProcessing | async)"><i
            class="fas fa-unlink"></i> {{ 'person.page.orcid.unlink' | translate }}</span>
          <span *ngIf="(unlinkProcessing | async)"><i
            class='fas fa-circle-notch fa-spin'></i> {{'person.page.orcid.unlink.processing' | translate}}</span>
        </button>
        <button *ngIf="(hasMissingOrcidAuthorizations() | async)" type="submit"
                class="btn btn-primary float-right" (click)="linkOrcid()">
          <span><i class="fas fa-check"></i> {{ 'person.page.orcid.grant-authorizations' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #orcidNotLinked>
  <div data-test="orcidNotLinked">
    <div class="row">
      <div class="col-2"><img alt="orcid-logo" src="../../../../assets/images/orcid.logo.icon.svg"/></div>
      <div class="col">
        <ds-alert [type]="'alert-info'">{{ getOrcidNotLinkedMessage() | async }}</ds-alert>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button class="btn btn-primary float-right" (click)="linkOrcid()">
          <i class="fas fa-link"></i>
          {{'person.page.orcid.link' | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>

