<div class="container">
  <ds-alert [type]="'alert-info'" [content]="'item.edit.authorizations.heading'"></ds-alert>
  <ds-resource-policies [resourceType]="'item'" [resourceName]="(getItemName() | async)"
    [resourceUUID]="(getItemUUID() | async)">
  </ds-resource-policies>
  <ng-container *ngFor="let bundle of (bundles$ | async); trackById">
    <ds-resource-policies [resourceType]="'bundle'" [resourceUUID]="bundle.id" [resourceName]="bundle.name">
    </ds-resource-policies>
    <ng-container *ngIf="(bundleBitstreamsMap.get(bundle.id)?.bitstreams | async)?.length > 0">
      <div class="card auth-bitstream-container">
        <div class="card-header">
          <button type="button" class="btn btn-outline-primary" (click)="collapseArea(bundle.id)"
            [attr.aria-expanded]="false" [attr.aria-controls]="bundle.id">
            {{ 'collection.edit.item.authorizations.show-bitstreams-button' | translate }} {{ nameService.getName(bundle) }}
          </button>
        </div>
        <div class="card-body" [id]="bundle.id" [ngbCollapse]="bundleBitstreamsMap.get(bundle.id).isCollapsed">
          <ng-container
            *ngFor="let bitstream of (bundleBitstreamsMap.get(bundle.id).bitstreams | async); trackById">
            <ds-resource-policies [resourceType]="'bitstream'" [resourceUUID]="bitstream.id"
              [resourceName]="bitstream.name"></ds-resource-policies>
          </ng-container>
          <div class="row justify-content-center" *ngIf="!bundleBitstreamsMap.get(bundle.id).allBitstreamsLoaded">
            <button type="button" class="btn btn-link" (click)="onBitstreamsLoad(bundle)">{{ 'collection.edit.item.authorizations.load-more-button' | translate }}</button>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="row justify-content-center" *ngIf="!allBundlesLoaded">
    <button type="button" class="btn btn-link" (click)="onBundleLoad()">{{ 'collection.edit.item.authorizations.load-bundle-button' | translate }}</button>
  </div>
</div>
