<ng-template #descTemplate>
  <span class="text-muted">
      <span class="item-list-project-title">
        <span [innerHTML]="dsoNameService.getName(mdRepresentation)"></span>
      </span>
  </span>
</ng-template>
<ds-truncatable [id]="mdRepresentation.id">
  <a [routerLink]="[itemPageRoute]"
     [innerHTML]="dsoNameService.getName(mdRepresentation)"
     [ngbTooltip]="dsoNameService.getName(mdRepresentation).length > 0 ? descTemplate : null"></a>
</ds-truncatable>