<div class="modal-header">
  <h4 class="modal-title">{{'vocabulary-treeview.header' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="p-3">
    <ds-vocabulary-treeview [vocabularyOptions]="vocabularyOptions"
                            [preloadLevel]="preloadLevel"
                            [selectedItems]="selectedItems"
                            [multiSelect]="multiSelect"
                            (select)="onSelect($event)">
    </ds-vocabulary-treeview>
  </div>
</div>
