<div class="flex-grow-1 ds-drop-list h-100" [class.disabled]="(draggingMdField$ | async) && (draggingMdField$ | async) !== mdField" cdkDropList (cdkDropListDropped)="drop($event)" role="table">
  <ds-dso-edit-metadata-value-headers role="presentation" [dsoType]="dsoType"></ds-dso-edit-metadata-value-headers>
  <ds-dso-edit-metadata-value *ngFor="let mdValue of form.fields[mdField]; let idx = index" role="presentation"
                              [dso]="dso"
                              [mdValue]="mdValue"
                              [dsoType]="dsoType"
                              [saving$]="saving$"
                              [isOnlyValue]="form.fields[mdField].length === 1"
                              (edit)="mdValue.editing = true"
                              (confirm)="mdValue.confirmChanges($event); form.resetReinstatable(); valueSaved.emit()"
                              (remove)="mdValue.change === DsoEditMetadataChangeTypeEnum.ADD ? form.remove(mdField, idx) : mdValue.change = DsoEditMetadataChangeTypeEnum.REMOVE; form.resetReinstatable(); valueSaved.emit()"
                              (undo)="mdValue.change === DsoEditMetadataChangeTypeEnum.ADD ? form.remove(mdField, idx) : mdValue.discard(); valueSaved.emit()"
                              (dragging)="$event ? draggingMdField$.next(mdField) : draggingMdField$.next(null)">
  </ds-dso-edit-metadata-value>
</div>
