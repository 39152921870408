<div class="home-background-container">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
          <p class="lead" [innerHtml]="'home.news.lead' | translate"></p>
      </div>
    </div>

    <div class="sub-home-teaser">
        <div class="sub-teaser-light">
            <div class="sub-teaser-content">
                <h2>{{ 'home.news.teaser.left.header' | translate}}</h2>
                <p [innerHtml]="'home.news.teaser.left.content' | translate"></p>
                <a href="/login" class="btn btn-secondary">{{ 'home.news.teaser.login' | translate}}</a>
            </div>
        </div>
        <div class="sub-teaser-dark">
            <div class="sub-teaser-content">
                <h2>{{ 'home.news.teaser.right.header' | translate}}</h2>
                <p [innerHtml]="'home.news.teaser.right.content' | translate"></p>
                <a href="/search?query=" class="btn btn-secondary">{{ 'home.news.teaser.search' | translate}}</a>
            </div>
        </div>
    </div>
  </div>
</div>
