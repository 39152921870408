<ds-themed-results-back-button *ngIf="showBackButton | async" [back]="back"></ds-themed-results-back-button>
<div class="d-flex flex-row">
  <ds-themed-item-page-title-field class="mr-auto" [item]="object">
  </ds-themed-item-page-title-field>
  <ds-dso-edit-menu></ds-dso-edit-menu>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"
                    [defaultImage]="'assets/images/person-placeholder.svg'"
                    [alt]="'thumbnail.person.alt'"
                    [placeholder]="'thumbnail.person.placeholder'">
      </ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <ng-container *ngIf="object.hasMetadata('person.identifier.orcid')">
      <a class="orcid-link" href="https://orcid.org/{{object.firstMetadataValue('person.identifier.orcid')}}" target="_blank">
        <span class="logo"><img src="assets/sub/images/orcid_16x16.png" [attr.alt]="'orcid.logo' | translate"/></span>
        https://orcid.org/{{object.firstMetadataValue('person.identifier.orcid')}}
      </a>
    </ng-container>
    <br/>
    <ng-container *ngIf="object.hasMetadata('person.identifier.gnd')">
      <a class="gnd-link" href="https://explore.gnd.network/gnd/{{object.firstMetadataValue('person.identifier.gnd')}}" target="_blank">
        <span class="logo"><img src="assets/sub/images/gnd_16x16.png" [attr.alt]="'orcid.logo' | translate"/></span>
        https://explore.gnd.network/gnd/{{object.firstMetadataValue('person.identifier.gnd')}}
      </a>
    </ng-container>
  </div>
  <div class="col-xs-12 col-md-7">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isProjectOfPerson'"
      [label]="'relationships.isProjectOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfPerson'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <ds-generic-item-page-field [item]="object"
      [fields]="['person.affiliation.name']"
      [label]="'person.page.affiliation'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['person.familyName']"
      [label]="'person.page.lastname'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['person.givenName']"
      [label]="'person.page.firstname'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
      [fields]="['dc.title']"
      [label]="'person.page.name'">
    </ds-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isAuthorOfPublication',
                                          filter: 'isAuthorOfPublication',
                                          configuration: 'default-relationships'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
