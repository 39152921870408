<a *ngIf="isVisible | async" class="d-flex flex-row"
   [tabIndex]="-1"
   [routerLink]="[searchLink]"
   [queryParams]="addQueryParams" queryParamsHandling="merge">
  <label class="mb-0 d-flex w-100">
    <input type="checkbox" [checked]="false" class="my-1 align-self-stretch filter-checkbox"/>
    <span class="w-100 pl-1 break-facet">
      <span class="float-right badge badge-secondary badge-pill mt-1 ml-1">{{filterValue.count | dsShortNumber}}</span>
      {{ 'search.filters.' + filterConfig.name + '.' + filterValue.value | translate: {default: filterValue.value} }}
    </span>
  </label>

</a>
