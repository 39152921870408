<div class="d-flex flex-row ds-field-row ds-header-row">
  <div class="lbl-cell">{{ dsoType + '.edit.metadata.headers.field' | translate }}</div>
  <div class="flex-grow-1">
    <div class="d-flex flex-row">
      <div class="flex-grow-1 ds-flex-cell ds-value-cell"><b class="dont-break-out preserve-line-breaks">{{ dsoType + '.edit.metadata.headers.value' | translate }}</b></div>
      <div class="ds-flex-cell ds-lang-cell"><b>{{ dsoType + '.edit.metadata.headers.language' | translate }}</b></div>
      <div class="text-center ds-flex-cell ds-edit-cell"><b>{{ dsoType + '.edit.metadata.headers.edit' | translate }}</b></div>
    </div>
  </div>
</div>
