<div class="container mb-5">
  <h2>{{'person.orcid.sync.setting' | translate}}</h2>
  <form #f="ngForm" (ngSubmit)="onSubmit(f.form)">
    <div class="row mb-3">
      <div class="col-md">
        <div class="card" data-test="sync-mode">
          <div class="card-header">{{ 'person.page.orcid.synchronization-mode'| translate }}</div>
          <div class="card-body">
            <div class="container">
              <div class="row">
                <ds-alert [type]="'alert-info'">
                  {{ 'person.page.orcid.synchronization-mode-message' | translate}}
                </ds-alert>
              </div>
              <div class="form-group row">
                <label for="syncMode">{{ 'person.page.orcid.synchronization-mode.label'| translate }}</label>
                <select class="form-control" [(ngModel)]="currentSyncMode" name="syncMode" id="syncMode"
                        required>
                  <option *ngFor="let syncMode of syncModes"
                          [value]="syncMode.value">{{ syncMode.label | translate }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md mb-3">
        <div class="card h-100" data-test="sync-mode-publication">
          <div class="card-header">{{ 'person.page.orcid.publications-preferences'| translate }}</div>
          <div class="card-body">
            <div class="container">
              <div class="row">
                <ds-alert [type]="'alert-info'">
                  {{ 'person.page.orcid.synchronization-mode-publication-message' | translate}}
                </ds-alert>
              </div>
              <div class="form-group">
                <div *ngFor="let option of syncPublicationOptions" class="row form-check">
                  <input type="radio" [(ngModel)]="currentSyncPublications"
                         name="syncPublications" id="publicationOption_{{option.value}}" [value]="option.value"
                         required>
                  <label for="publicationOption_{{option.value}}"
                         class="ml-2">{{option.label | translate}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md mb-3">
        <div class="card h-100" data-test="sync-mode-funding">
          <div class="card-header">{{ 'person.page.orcid.funding-preferences'| translate }}</div>
          <div class="card-body">
            <div class="container">
              <div class="row">
                <ds-alert [type]="'alert-info'">
                  {{ 'person.page.orcid.synchronization-mode-funding-message' | translate}}
                </ds-alert>
              </div>
              <div class="form-group">
                <div *ngFor="let option of syncFundingOptions" class="row form-check">
                  <input type="radio" [(ngModel)]="currentSyncFunding"
                         name="syncFundings" id="fundingOption_{{option.value}}" [value]="option.value"
                         required>
                  <label for="fundingOption_{{option.value}}" class="ml-2">{{option.label | translate}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md mb-3">
        <div class="card h-100" data-test="profile-preferences">
          <div class="card-header">{{ 'person.page.orcid.profile-preferences'| translate }}</div>
          <div class="card-body">
            <div class="container">
              <div class="row">
                <ds-alert [type]="'alert-info'">
                  {{ 'person.page.orcid.synchronization-mode-profile-message' | translate}}
                </ds-alert>
              </div>
              <div class="form-group">
                <div *ngFor="let option of syncProfileOptions" class="row form-check">
                  <input type="checkbox" [(ngModel)]="option.checked"
                         name="syncProfile_{{option.value}}" id="profileOption_{{option.value}}"
                         [value]="option.value">
                  <label for="profileOption_{{option.value}}" class="ml-2">{{option.label | translate}}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button type="submit" class="btn btn-primary float-right">
          <i class="fas fa-edit"></i>
          {{ 'person.page.orcid.save.preference.changes' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
